<template>
  <div class="investorrelations">
    <div class="banner_container contactus_banner border_text_box">
      <div class="border_text">Investor Relations</div>
      <h1 class="border_text_title">投资者关系</h1>
    </div>
    <div class="bread_crumbs container_width">
      <span>您的位置：首页 > </span>
      <span class="current">投资者关系</span>
    </div>
    <div class="tab_container">
      <div class="tab_box container_width">
        <ul>
          <li :class="{current_tab : num === 0}" @click="tab(0)">公司介绍</li>
          <li :class="{current_tab : num === 1}" @click="tab(1)">业绩亮点</li>
          <li :class="{current_tab : num === 2}" @click="tab(2)">财务报告</li>
          <li :class="{current_tab : num === 3}" @click="tab(3)">公告及通函</li>
          <li :class="{current_tab : num === 4}" @click="tab(4)">投资者活动资讯</li>
        </ul>
      </div>
      <div class="tab_content_box container_width">
        <div class="basic_information border_text_box" v-if="num === 0">
          <h2 class="border_text">Basic Information</h2>
          <h3 class="border_text_title">基本信息</h3>
          <div class="introduction">
            <div class="text">武汉贤牛科技有限公司2019年2月22日,成立于武汉洪山区，公司办公地点环境优美。且交通便利。武汉海岸跃动网络科技有限公司，以推动效果营销行业发展为己任,专注致力于信息咨询、效果营销、SAS软件服务、跨境电商、进出口贸易,以及各类跨境电商服务解决方案领域,是一家创新型网络科技企业。以前瞻的市场洞悉力,审视效果营销行业发展态势,积聚开拓创新的力量全力挖掘行业内在价值,我们将持续创业的品质融入到自身发展的每个角落,构建了全产业链效果营销服务解决方案体系。最好的产品、最好的服务、最好的信誉，完成众多大型合作配套服务，也愿意成为您最可信赖的长期合作伙伴。</div>
            <img src="../assets/imgs/investorrelations/basic_information_img.webp" alt="">
          </div>
          <div class="table_box">
              <table border="1" rules="all" width="100%" class="table">
                <tr>
                    <th>公司名称</th>
                    <td>武汉贤牛科技有限公司</td>
                </tr>
                <tr>
                    <th>股份代码</th>
                    <td>3799</td>
                </tr>
                <tr>
                    <th>行业</th>
                    <td>互联网</td>
                </tr>
                <tr>
                    <th>主要业务</th>
                    <td>信息咨询、效果营销、SAS软件服务、跨境电商、进出口贸易</td>
                </tr>
                <tr>
                    <th>董事长兼行政总裁</th>
                    <td>董事长兼行政总裁</td>
                </tr>
                <tr>
                    <th>联席公司秘书</th>
                    <td>联席公司秘书</td>
                </tr>
                <tr>
                    <th>公司名称</th>
                    <td>武汉贤牛科技有限公司</td>
                </tr>
                <tr>
                    <th>公司总部地址</th>
                    <td>武汉市洪山区万科中心502</td>
                </tr>
                <tr>
                    <th>会计师事务所</th>
                    <td>会计师事务所</td>
                </tr>
                <tr>
                    <th>电话号码</th>
                    <td>13588762602</td>
                </tr>
                <tr>
                    <th>公司网址</th>
                    <td>wuhanxianniu.com</td>
                </tr>
            </table>
          </div>
        </div>
        <div class="performance_highlights border_text_box" v-if="num === 1">
          <h2 class="border_text">Performance Highlights</h2>
          <h3 class="border_text_title">业绩亮点</h3>
          <div class="time">发布时间：2022-05-02 来源 ：贤牛集团</div>
          <div class="content_line"></div>
          <div class="performance_list">
            <img src="../assets/imgs/investorrelations/performance_highlights_left.png" alt="">
            <img src="../assets/imgs/investorrelations/performance_highlights_right.png" alt="">
          </div>
        </div>
        <div class="financial_report border_text_box" v-if="num === 2">
          <h2 class="border_text">Financial Report</h2>
          <h3 class="border_text_title">财务报告</h3>
          <div class="content_line"></div>
          <ul class="repopt_list">
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
          </ul>
          <div class="paginator_box container_width">
            <ul class="paginator">
              <img src="../assets/imgs/investorrelations/previous.webp" alt="" class="previous">
              <li class="current_page">1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <img src="../assets/imgs/investorrelations/next.webp" alt="" class="next">
            </ul>
          </div>
        </div>
        <div class="financial_report border_text_box" v-if="num === 3">
          <h2 class="border_text">Announcements and Circulars</h2>
          <h3 class="border_text_title">公告及通函</h3>
          <div class="content_line"></div>
          <ul class="repopt_list">
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
          </ul>
          <div class="paginator_box container_width">
            <ul class="paginator">
              <img src="../assets/imgs/investorrelations/previous.webp" alt="" class="previous">
              <li class="current_page">1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <img src="../assets/imgs/investorrelations/next.webp" alt="" class="next">
            </ul>
          </div>
        </div>
        <div class="financial_report border_text_box" v-if="num === 4">
          <h2 class="border_text">Investor Activities Information</h2>
          <h3 class="border_text_title">投资者活动资讯</h3>
          <div class="content_line"></div>
          <ul class="repopt_list">
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
            <li>
              <div class="time">
                <div class="day">21</div>
                <div class="mon">apr</div>
                <div class="year">2022</div>
              </div>
              <div class="title_box">
                <h2>年度报告2021</h2>
                <span class="author">发布者：贤牛集团 浏览次数：5532</span>
              </div>
            </li>
          </ul>
          <div class="paginator_box container_width">
            <ul class="paginator">
              <img src="../assets/imgs/investorrelations/previous.webp" alt="" class="previous">
              <li class="current_page">1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <img src="../assets/imgs/investorrelations/next.webp" alt="" class="next">
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/scss/animation.scss'
import '../assets/scss/communal.scss'
export default {
  name: "investorrelations",
  data() {
    return {
      num:0,
    }
  },
  methods: {
    tab(num) {
      this.num = num
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/layout.scss';
.investorrelations {
    .contactus_banner {
        background: url('../assets/imgs/investorrelations/banner.webp') 50% 50%;
        background-size: cover;
        .border_text_title {
          color: #fff
        }
    }
    .tab_container {
      .tab_box {
        ul {
          @include flexrl();
          margin-bottom: 90px;
          li {
            text-align: center;
            cursor: pointer;
            width: calc(100% / 5 - 60px);
            padding: 26px 10px;
            border-radius: 20px;
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25); 
            font-size: 16px;
            color: #161616;
            line-height: 20px;
          }
          .current_tab {
            background: linear-gradient(113deg,#73e0a9, #5b68df 100%);
            color: #fff;
          }
        }
      }
      .tab_content_box {
        .basic_information {
          .introduction {
            @include flexrl();
            margin-bottom: 130px;
            .text {
              width: 50%;
              font-size: 13px;
              font-weight: Normal;
              text-align: left;
              color: #464949;
              line-height: 20px;
            }
            img {
              width: 40%;
            }
          }
          .table_box {
            overflow:auto;width:100%;
            font-size: 20px;
            color: #161616;
            line-height: 20px;
            .table {
              th {
                width: 220px;
                font-weight: 400;
                text-align: right;
                padding: 18px 55px 18px 0;
              }
              td {
                text-align: left;
                padding-left: 55px;
              }
            }
          }
        }
        .performance_highlights {
          .border_text_title {
            margin-bottom: 15px;
          }
          .time {
            font-size: 14px;
            color: #464949;
            line-height: 14px;
          }
          .content_line {
            margin: 40px 0 40px;
          }
          .performance_list {
            @include flexrl();
            img {
              width: 48%;
            }
          }
        }
      }
    }
}
@media screen and (max-width: 1160px){}
@media screen and (max-width: 820px){}
@media screen and (max-width: 768px){
  .investorrelations {
    .tab_container {
      .tab_box {
        ul {
          flex-wrap: wrap;
          justify-content: center;
          li {
            width: auto;
            margin-bottom: 20px;
            padding: 15px 15px;
            border-radius: 14px;
            font-size: 13px;
            margin-right: 20px;
          }
        }
      }
      .tab_content_box {
        .basic_information {
          .introduction {
            flex-direction: column;
            margin-bottom: 60px;
            .text {
              width: 100%;
              margin-bottom: 40px;
            }
            img {
              width: 100%;
            }
          }
          .table_box {
            .table {
              font-size: 13px;
              th {
                width: 40%;
                padding: 18px 15px 18px 0;
              }
              td {

                width: 60%;
                padding: 15px 0 15px 15px;
              }
            }
          }
        }
        .performance_highlights {
          .performance_list {
            flex-direction: column;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 425px){
  .investorrelations {
    .tab_container {
      .tab_box {
        ul {
          margin-bottom: 40px;
          li:nth-child(3) {
            margin-right: 0px;
          }
        }
      }
    } 
  }
}
</style>